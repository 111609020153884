import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const PersonCard = ({ person }) => {
  return (
    <div className="sm:max-w-md overflow-hidden border-b border-gray-600">
      <Link to={person.fields.slug}>
        <PersonImage image={person.frontmatter.image} />
        <div className="px-6 ">
          <div className="text-lg leading-tight">{person.frontmatter.name}</div>
          <p className="text-gray-700 text-base">{person.frontmatter.role}</p>
        </div>
      </Link>
    </div>
  )
}

const PersonImage = ({ image }) => {
  const imageStyle = {
    width: "100%",
    height: "150px",
    overflow: "hidden",
  }
  return <Img style={imageStyle} fluid={image.childImageSharp.fluid} />
}

const PersonList = ({ data }) => {
  const { edges: people } = data.allMarkdownRemark
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-6 gap-6">
      {people &&
        people.map(({ node: person }, i) => (
          <PersonCard key={i} person={person} />
        ))}
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "person-page" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                name
                role
                image {
                  id
                  childImageSharp {
                    fluid(maxWidth: 200, quality: 60) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <PersonList data={data} />}
  ></StaticQuery>
)
