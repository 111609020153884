import React from "react"

import Layout from "../../components/layout"
import PersonList from "../../components/PersonList"

const PeoplePage = () => {
  return (
    <Layout>
      <PersonList />
    </Layout>
  )
}

export default PeoplePage
